import logo from './logo.svg';
import './App.css';
import Navpage from './Navpage';
import Home from './Home';
import Aos from 'aos';
import { useEffect } from 'react';
import About from './About';
import Service from './Service';
import Footer from './Footer';

function App() {

  useEffect(() => {
    // Initialize AOS after the component mounts
    Aos.init({
      once:true,
      delay:700 
    });
  }, []);
  return (
    <div className="App">
  <Navpage></Navpage>
  <Home></Home>
  <About></About><br></br>
  
  <Service></Service>
  <Footer></Footer>
  <div>
  <a
                href="tel:+916381612638"
                className="phone-float"
                target="_blank"
                rel="noopener noreferrer"
            >
               <i class="fa-solid fa-phone"></i>
            </a>
  </div>
<div><a
                href="https://wa.me/+916381612638?text=Hello!"
                className="whatsapp-float"
                target="_blank"
            >
               <i className="fab fa-whatsapp fa-2x text-2xl"></i>
            </a></div>
    </div>
  );
}

export default App;
