function About() {
    return (
      <>
        <h1 id="about" className="text-5xl text-center text-gray-800 font-extrabold mb-6 p-10" style={{fontFamily:'"Orelega One", serif'}}>About </h1>
        <div className="container mx-auto flex flex-col sm:flex-row justify-center "  data-aos="fade-right">
          {/* Right Side: Image */}
          <div className=" sm:w-1/2 p-3">
            <img src="/dfit.jpg" alt="About Us Image" className="w-full h-full object-cover rounded-lg" />
          </div>
  
          {/* Left Side: Content */}
          <div className="  sm:w-1/2 p-6 py-8 " data-aos="fade-left">
          <div className="mb-8">
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-start">About the Trainer</h2>
            <p className="text-lg text-gray-600 text-start">
              I am  Sarath, an International certified trainer with over 12 years of experience in helping individuals achieve their fitness goals. My approach is personalized to fit your unique needs, and I am passionate about guiding you to fitness.
            </p>
          </div>
            <h2 className="text-2xl font-bold text-gray-800 mb-4 text-start">Our Mission</h2>
            <p className="text-lg text-gray-600 text-start">
              At D-Fitness, we are committed to helping you achieve your fitness goals with a personalized approach.
              Whether you're looking to lose weight, gain strength, or improve your overall health, we are here to guide you every step of the way.
            </p>
            <p className="text-lg text-gray-600 mt-4 text-start">
              Our goal is to provide you with the tools, motivation, and expert guidance you need to make lasting changes to your lifestyle.
            </p>
          </div>
        </div>
      </>
    );
  }
  
  export default About;
  