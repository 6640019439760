function Footer(){
    return(<>
    

    <footer id="contact" className="bg-gray-900 text-white py-10">
  <div className="container mx-auto px-6 md:px-12">
    <div className="flex flex-wrap justify-between items-start">
      {/* Logo & Tagline */}
      <div className="w-full md:w-1/4 mb-6 md:mb-0">
        <h3 className="text-xl font-bold mb-3">Transform Your Body</h3>
        <p className="text-gray-400">
          Join our 60-day weight loss journey and achieve your fitness goals with professional guidance.
        </p>
      </div>

      {/* Navigation Links */}
     

      {/* Contact Info */}
      <div className="w-full md:w-1/4 mb-6 md:mb-0">
        <h4 className="text-lg font-semibold mb-3 ">Contact Us</h4>
        <ul>
          <li className="text-gray-400"><a href="mailto:dfitnessclub005@gmail.com">Email: dfitnessclub005@gmail.com</a></li>
          <li className="text-gray-400">Phone: +91 6381612638</li>
          {/* <li className="text-gray-400">Address: 123 xyz street xyz city</li> */}
        </ul>
      </div>

      {/* Social Media Icons */}
      <div className="w-full md:w-1/4 mb-6 md:mb-0">
        <h4 className="text-lg font-semibold mb-3">Follow Us</h4>
        <div className="flex space-x-4 py-2 px-20 ">
          <a href="#" className="text-gray-400 hover:text-white transition hover:scale-150 " target="_blank">
            <i className="fab fa-facebook fa-2x"></i> {/* Facebook Icon */}
          </a>
          <a href="https://www.instagram.com/d_fitness_club04/" target="_blank" className="text-gray-400 hover:text-white transition hover:scale-150">
            <i className="fab fa-instagram fa-2x"></i> {/* Instagram Icon */}
          </a>
          <a href="https://api.whatsapp.com/send/?phone=%2B6381612638&text=Hello%21&type=phone_number&app_absent=0" target="_blank" className="text-gray-400 hover:text-white transition hover:scale-150">
  <i className="fab fa-whatsapp fa-2x"></i> {/* WhatsApp Icon */}
</a>
          <a href="#" className="text-gray-400 hover:text-white transition hover:scale-150" target="_blank">
            <i className="fab fa-youtube fa-2x"></i> {/* YouTube Icon */}
          </a>
        </div>
      </div>
    </div>

    {/* Divider Line */}
    <div className="border-t border-gray-700 mt-8 pt-4 text-center">
      <p className="text-gray-400 text-sm">
        &copy; {new Date().getFullYear()} D-Fitness Club. All Rights Reserved.
      </p>
      <p className="text-gray-400 text-sm font-extralight"><a target="_blank" href="https://riyadvisoftwaretechnologies.com/">Designed By @Riyadvi software Technologies</a></p>
    </div>
  </div>
</footer>

    
    </>)
}
export default Footer;