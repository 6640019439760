import { useState } from 'react';

function Navpage() {
  const [isMenuOpen, setIsMenuOpen] = useState(false);  // State to control the dropdown

  const toggleMenu = () => {
    setIsMenuOpen(!isMenuOpen);  // Toggle the dropdown visibility
  };

  return (
    <nav className="bg-transparent absolute w-full top-0 z-10 py-5">
      <div className="max-w-7xl mx-auto px-2 sm:px-6 lg:px-8">
        <div className="relative flex items-center justify-between h-16">
          {/* Navbar Brand (Left-Aligned) */}
          <div className="flex-shrink-0">
  <a href="#home" className="text-4xl font-extrabold text-white">
    <span className="text-red-500">D-Fitness</span> Club
  </a>
</div>

          {/* Navbar Links (Desktop View) */}
          <div className="hidden sm:flex sm:ml-6 flex-1 place-content-end space-x-5  px-0">
            <a
              href="#home"
              className="text-white  hover:text-red-700 px-3 py-2 rounded-md text-lg font-medium"
            >
              Home
            </a>
            <a
              href="#about"
              className="text-white  hover:text-red-700 px-3 py-2 rounded-md text-lg font-medium"
            >
              About
            </a>
            <a
              href="#service"
              className="text-white  hover:text-red-700 px-3 py-2 rounded-md text-lg font-medium"
            >
              Service
            </a>
            <a
              href="#result"
              className="text-white  hover:text-red-700 px-3 py-2 rounded-md text-lg font-medium"
            >
              Results
            </a>
            <a
              href="#contact"
              className="text-white  hover:text-red-700 px-3 py-2 rounded-md text-lg font-medium"
            >
              Contact
            </a>
          </div>

          {/* Navbar Dropdown Toggle Button (Mobile View) */}
          <div className="sm:hidden">
            <button
              onClick={toggleMenu}
              className="text-white  hover:text-red-700 px-3 py-2 rounded-md text-lg font-medium"
            >
              {/* Hamburger icon for mobile menu */}
              <svg
                className="h-6 w-6"
                xmlns="http://www.w3.org/2000/svg"
                fill="none"
                viewBox="0 0 24 24"
                stroke="currentColor"
              >
                <path
                  strokeLinecap="round"
                  strokeLinejoin="round"
                  strokeWidth="2"
                  d="M4 6h16M4 12h16M4 18h16"
                />
              </svg>
            </button>
          </div>
        </div>
      </div>

      {/* Mobile Dropdown Menu */}
      <div
        className={`sm:hidden ${isMenuOpen ? 'scale-y-100' : 'scale-y-0'} bg-black bg-opacity-80 origin-top transition-all absolute w-screen  `}
      >
        <div className="px-2 pt-2 pb-3 space-y-1 sm:px-3">
          <a
            href="#home"
            className="text-white block px-3 py-2 rounded-md text-lg font-medium"
            onClick={toggleMenu} // Close the dropdown on click
          >
            Home
          </a>
          <a
            href="#about"
            className="text-white block px-3 py-2 rounded-md text-lg font-medium"
            onClick={toggleMenu} // Close the dropdown on click
          >
            About
          </a>
          <a
            href="#service"
            className="text-white block px-3 py-2 rounded-md text-lg font-medium"
            onClick={toggleMenu} // Close the dropdown on click
          >
            Service
          </a>
          <a
            href="#result"
            className="text-white block px-3 py-2 rounded-md text-lg font-medium"
            onClick={toggleMenu} // Close the dropdown on click
          >
            Results
          </a>
          <a
            href="#contact"
            className="text-white block px-3 py-2 rounded-md text-lg font-medium"
            onClick={toggleMenu} // Close the dropdown on click
          >
           Contact
          </a>
        </div>
      </div>
    </nav>
  );
}

export default Navpage;
